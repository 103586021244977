import {
    Box,
    Typography,
    Button,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
  import Navbar from "../../components/navbar";
import notFoundImg from '../../assets/404.png';
  
  const NotFound = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
  
    const handleGoBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
  
    return (
      <Box>
        <Navbar color="black" />
        <Box
          sx={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "2rem",
          }}
        >
          {/* Icon */}
          <Box
            sx={{
              width: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Box
                                component="img"
                                src={notFoundImg}
                                alt="404 Not Found!"
                                sx={{
                                    height: "auto",
                                    width : isMobile ? "25em" : "30em"
                                }}
                            />
          </Box>
  
          {/* Message */}
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ marginBottom: "0.5rem" }}
          >
            Ooops... Cette page n'est pas disponible.
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ marginBottom: "2rem" }}>
            Désolé, nous n'avons pas pu trouver la page que vous cherchiez.
          </Typography>
  
          {/* Go Back Button */}
          <Button
            variant="contained"
            onClick={handleGoBack}
            sx={{
              backgroundColor: "#69A88D",
              color :"#FFFFFF",
              fontSize :"1.1em",
              fontWeight : "Bold",
              "&:hover": { backgroundColor: "#57A07D" },
              padding: "0.8rem 2rem",
              borderRadius: "25px",
              textTransform :"none"
            }}
          >
            Revenir en arrière
          </Button>
        </Box>
      </Box>
    );
  };
  
  export default NotFound;
  