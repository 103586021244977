import './App.css';
import { BrowserRouter as Router , Routes , Route, Navigate } from "react-router-dom";
import { CssBaseline , ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { themeSettings } from "./theme";
import Home from './pages/landing/landing';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import NotFound from './pages/notFound/notfound';
import DoctorsForm from './pages/doctorsForm/doctorsForm';
import Termes from './pages/termes-conditions/termes-conditions';
import Confidentialite from './pages/confidentialite-donnees/confidentialite-donnees';
import ScrollToTop from './components/ScrollToTop ';


function App() {


  const theme = useMemo(() => createTheme(themeSettings('light')), []);

  return (
    <div className="app">
      
      <Router>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
          <Routes>
          <Route path="/"  element={ <Home /> }   />
          <Route path="/about"  element={ <About /> }   />
          <Route path="/contact"  element={ <Contact /> }   />
          <Route path="/doctor-signup"  element={ <DoctorsForm /> }   />
          <Route path="/termes-conditions"  element={ <Termes /> }   />
          <Route path="/confidentialite-donnees"  element={ <Confidentialite /> }   />

          
          <Route path="*" element={<NotFound />} />
          </Routes>
          </ThemeProvider>
        </Router>
    </div>  
  );
}

export default App;