import React from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CountUp from "react-countup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import TeamImg from "../../assets/female.png";
import Smiley from "../../assets/smiley.png";
import Stéto from "../../assets/steto.png";
import Like from "../../assets/pouce.png";
import Check from "../../assets/check.png";
import FAQSection from "../../components/Faq";

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <section
        style={{
          backgroundColor: "#69A88D",
          height: "40vh",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color="white"
            sx={{
              marginBottom: "1em",
              fontSize: isMobile ? "1.8rem" : "2.5rem",
              width: isMobile ? "80%" : "70%",
            }}
          >
            Chez FertiConnect, nous sommes là pour vous offrir écoute, soutien et solutions adaptées à <br/> vos besoins
          </Typography>
        </Box>
      </section>

      {/* Key Metrics Section */}
      <Box
      sx={{
        height: "40vh",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        padding: isMobile ? "0em 1em" : "0em 10em",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <img src={Smiley} alt="Smiley" style={{ width: 75, marginBottom: "1rem" }} />
        <Typography
          sx={{
            fontWeight: 700,
            color: "#69A88D",
            fontFamily: "Oswald",
            fontSize: "3em",
            paddingBottom: ".2em",
            width: "6ch", // Fixed width ensures stability
            margin: "0 auto", // Centers the number
            fontVariantNumeric: "tabular-nums", // Consistent number width
            WebkitFontSmoothing: "antialiased", // Smooth font rendering
            fontFeatureSettings: '"tnum"', // Tabular numbers for better alignment
          }}
          variant="h2"
        >
          <CountUp start={0} end={200} duration={3} separator=" " suffix=" K" />
        </Typography>
        <Typography sx={{ color: "#969696", fontFamily: "Oswald" }}>
          Patients Satisfaits
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <img src={Stéto} alt="Stéto" style={{ width: 75, marginBottom: "1rem" }} />
        <Typography
          sx={{
            fontWeight: 700,
            color: "#69A88D",
            fontFamily: "Oswald",
            fontSize: "3em",
            paddingBottom: ".2em",
            width: "6ch", // Fixed width ensures stability
            margin: "0 auto", // Centers the number
            fontVariantNumeric: "tabular-nums", // Consistent number width
            WebkitFontSmoothing: "antialiased", // Smooth font rendering
            fontFeatureSettings: '"tnum"', // Tabular numbers for better alignment
          }}
          variant="h2"
        >
          <CountUp start={0} end={100} duration={2.5} />
        </Typography>
        <Typography sx={{ color: "#969696", fontFamily: "Oswald" }}>Médecins</Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <img src={Like} alt="Like" style={{ width: 75, marginBottom: "1rem" }} />
        <Typography
          sx={{
            fontWeight: 700,
            color: "#69A88D",
            fontFamily: "Oswald",
            fontSize: "3em",
            paddingBottom: ".2em",
            width: "6ch", // Fixed width ensures stability
            margin: "0 auto", // Centers the number
            fontVariantNumeric: "tabular-nums", // Consistent number width
            WebkitFontSmoothing: "antialiased", // Smooth font rendering
            fontFeatureSettings: '"tnum"', // Tabular numbers for better alignment
          }}
          variant="h2"
        >
          <CountUp start={0} end={1200} duration={3.5} />
        </Typography>
        <Typography sx={{ color: "#969696", fontFamily: "Oswald" }}>Avis Positifs</Typography>
      </Box>
    </Box>

      {/* History Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignContent: "center" , 
          alignItems :"center",
          justifyContent :"space-around",
          width :"100%",
          padding :'5em 2em'
        }}
      >
        <Box  sx={{
          maxWidth : "45em",
          height :"100%",
          display :"flex",
          flexDirection : "column",
          alignItems :"left",
          justifyContent :"space-between",
          gap : "2em"
        }}>

          <Box>
          <Box sx={{
            width :"5em",
            height :"2px",
            background :"#E4E4E4",
            marginBottom :"1.5em"
          }} >
          </Box>
          <Typography sx={{
            color: '#969696',
            fontWeight :"bold"
          }}  variant="h4" >
          Notre Histoire :
          </Typography>
          </Box>
          
          <Box>
          <Typography sx={{ color : '#333333' , fontWeight :"500" , marginBottom :".4em"}} variant="h2" >
            Pourquoi FertiConnect ?
          </Typography>
          <Typography sx={{ color :"#969696" }} gutterBottom>
            Car avec FertiConnect, vous n'êtes pas seul dans votre parcours de santé
            reproductive. Nous sommes là pour vous accompagner à chaque étape, en 
            vous offrant les ressources et le soutien nécessaires pour naviguer avec 
            succès dans ce voyage personnel.
          </Typography>
          </Box>

          <Box>
          <Typography sx={{ color : '#333333' , fontWeight :"500", marginBottom :".4em"}} variant="h2" >
            Comment est née FertiConnect ?
          </Typography>
          <Typography sx={{ color :"#969696" }} gutterBottom>
            Lors de l’exploration des pathologies de  reproduction comme l’infertilité 
            qui est devenu un enjeu de santé publique en Algérie, les patients se 
            retrouvent  confrontés à divers obstacles,  notamment le manque de 
            coordination et de communication entre les parties impliqués, ainsi que la 
            complexité de ce parcours.
          </Typography>
          </Box>

          <Box>
          <Typography sx={{ color : '#333333' , fontWeight :"500" , marginBottom :".4em"}} variant="h2" >
            Suite à ce constat <br/> FertiConnect est né en 2024.
          </Typography>
          <Typography sx={{ color :"#969696" }} >
            FertiConnect naît d'une conviction révolutionnaire ou chaque parcours de 
            santé reproductive est une trajectoire unique méritant un accompagnement 
            holistique, bienveillant et profondément personnalisé. Notre vision 
            transcende les approches traditionnelles en créant un écosystème de bien-
            être riche de possibilités infinies où la santé reproductive devient un voyage 
            de découverte de soi, libéré des tabous et des jugements. Nous offrons à 
            chaque individu les outils nécessaires pour comprendre, explorer sa propre 
            histoire reproductive.
          </Typography>
          </Box>
        </Box>
        <Box >
          <img
            src={TeamImg}
            alt="Doctor"
            style={{ width: isMobile ? "27em" : "30em", borderRadius: isMobile ? "0px" : "25px" , marginTop : isMobile ? "2em" : "0em" }}
          />
        </Box>
      </Box>

      {/* Missions Section */}

          <Box sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "column",
            alignContent: "center" , 
            alignItems :"left",
            justifyContent :"space-around",
            width :"100%",
             padding: isMobile ? '2em 2em' : '4em 10em'
          }} >


          <Box  >
          <Box sx={{
            width :"5em",
            height :"2px",
            background :"#E4E4E4",
            marginBottom :"1.5em"
            
          }} >
          </Box>
          <Typography sx={{
            color: '#969696',
            fontWeight :"bold"
          }}  variant="h4" >
          Nos missions :
          </Typography>
          </Box>

          <Box
            sx={{
             
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
          

  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
      gap: '2em',
      paddingTop : "2em"
    }}
  >
    <Card
      sx={{
        padding: '2em',
        boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
        borderRadius: '25px',
        textAlign: 'left',
        background :"#F8FAFC"
      }}
    >
      <Box sx={{display :"flex" , flexDirection :"row" , alignItems :"center" , alignContent :"center" , gap :"1em"}} >
      <img src={Check} alt="check" style={{ width: 50 , marginBottom: "1rem" }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333333', marginBottom: '1em' }}>
      Accompagnement Personnalisé
      </Typography>
      </Box>
      <Typography sx={{ color: '#969696' }}>
      Nous nous engageons à fournir un soutien sur mesure, adapté aux besoins spécifiques de chaque personne, afin d'assurer un suivi complet tout au long du parcours de santé reproductive.
      </Typography>
    </Card>

    <Card
      sx={{
        padding: '2em',
        boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
        borderRadius: '25px',
        textAlign: 'left',
        background :"#F8FAFC"
      }}
    >
      <Box sx={{display :"flex" , flexDirection :"row" , alignItems :"center" , alignContent :"center" , gap :"1em"}} >
      <img src={Check} alt="check" style={{ width: 50 , marginBottom: "1rem" }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333333', marginBottom: '1em' }}>
      Création d'une Communauté Inclusive
      </Typography>
      </Box>
      <Typography sx={{ color: '#969696' }}>
      FertiConnect favorise un dialogue ouvert autour des enjeux de la santé reproductive. Nous encourageons le partage d'expériences et le soutien mutuel pour aider chacun à se sentir moins isolé dans son parcours
      </Typography>
    </Card>

    <Card
      sx={{
        padding: '2em',
        boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
        borderRadius: '25px',
        textAlign: 'left',
        background :"#F8FAFC"
      }}
    >
      <Box sx={{display :"flex" , flexDirection :"row" , alignItems :"center" , alignContent :"center" , gap :"1em"}} >
      <img src={Check} alt="check" style={{ width: 50 , marginBottom: "1rem" }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333333', marginBottom: '1em' }}>
      Accès à des Informations Fiables
      </Typography>
      </Box>
      <Typography sx={{ color: '#969696' }}>
      Nous croyons fermement que chacun mérite d'avoir accès à des informations précises et basées sur des preuves. C'est pourquoi nous collaborons avec des experts médicaux pour offrir un contenu de qualité.
      </Typography>
    </Card>

    <Card
      sx={{
        padding: '2em',
        boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
        borderRadius: '25px',
        textAlign: 'left',
        background :"#F8FAFC"
      }}
    >
      <Box sx={{display :"flex" , flexDirection :"row" , alignItems :"center" , alignContent :"center" , gap :"1em"}} >
      <img src={Check} alt="check" style={{ width: 50 , marginBottom: "1rem" }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333333', marginBottom: '1em' }}>
        Protection de la Vie Privée
      </Typography>
      </Box>
      <Typography sx={{ color: '#969696' }}>
        La confidentialité et la sécurité des données personnelles sont au cœur de notre mission.
        Nous garantissons une plateforme sécurisée où vous pouvez explorer votre santé reproductive
        en toute confiance.
      </Typography>
    </Card>
  </Box>
</Box>
          </Box>

          {/* Team Section */}
<Box
  sx={{
    padding: isMobile ? "2em 1em" : "4em 5em",
    textAlign: "left",
    backgroundColor: "#F8FAFC",
    boxShadow: '6px 7px 22px rgba(0, 0, 0, 0.08)',
    borderRadius : "25px",
    margin : isMobile ? "0em 2em" : "0em 7em"
  }}
>

  <Box>
          <Box sx={{
            width :"5em",
            height :"2px",
            background :"#E4E4E4",
            marginBottom :"1.5em"
          }} >
          </Box>
          <Typography sx={{
            color: '#969696',
            fontWeight :"bold"
          }}  variant="h4" >
          Notre équipe :
          </Typography>
          </Box>

  <Typography
    variant="h3"
    sx={{ fontWeight: "bold", margin: "1.5em 0em", color: "#333333" }}
  >
    Equipe FertiConnect :
  </Typography>

  <Box
  sx={{
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between", // Equally separate the cards
    gap: "2em",
    flexWrap: "wrap", // Wrap the cards for better responsiveness
  }}
>
  <Card
    sx={{
      flex: "1 1 45%", // Make the cards take an equal portion of the available space (adjust as needed)
      maxWidth: isMobile ? "100%" : "45%",
      padding: "2em",
      boxShadow: "6px 7px 22px rgba(0, 0, 0, 0.08)",
      borderRadius: "25px",
      background: "#FFFFFF",
    }}
  >
    <Typography
      variant="h4"
      sx={{ fontWeight: "bold", marginBottom: "1em", color: "#333333" }}
    >
      ZEGHIMI Aya,{" "}
      <Typography
        variant="h4"
        component="span"
        sx={{ color: "#69A88D", fontWeight: "bold" }}
      >
        PhD
      </Typography>
    </Typography>
    <Typography sx={{ color: "#000000" , fontSize :"36" }}>
      Spécialiste en biologie, physiologie et physiopathologie de la
      reproduction, ainsi qu'en génétique du développement et cancérologie. Avec
      un doctorat en biologie, Aya apporte une expertise approfondie dans les
      domaines critiques de la santé reproductive. Son engagement envers
      l'amélioration des soins de santé reproductive se traduit par sa recherche
      et son travail sur des solutions innovantes pour les patients.
    </Typography>
  </Card>

  <Card
    sx={{
      flex: "1 1 45%",
      maxWidth: isMobile ? "100%" : "45%",
      padding: "2em",
      boxShadow: "6px 7px 22px rgba(0, 0, 0, 0.08)",
      borderRadius: "25px",
      background: "#FFFFFF",
    }}
  >
    <Typography
      variant="h4"
      sx={{ fontWeight: "bold", marginBottom: "1em", color: "#333333" }}
    >
      DJEMA Israa,{" "}
      <Typography
        variant="h4"
        component="span"
        sx={{ color: "#69A88D", fontWeight: "bold" }}
      >
        MSc
      </Typography>
    </Typography>
    <Typography sx={{ color: "#000000" , fontSize :"36" }}>
      Titulaire d'un master en biologie et physiologie de la reproduction, Israa
      travaille dans un laboratoire d'analyses médicales. Son expérience
      pratique dans le domaine de la santé reproductive lui permet d'apporter
      une perspective précieuse sur les besoins des patients et les défis
      auxquels ils sont confrontés.
    </Typography>
  </Card>
</Box>

</Box>  


 <FAQSection isMobile={isMobile} />


      


      <Footer />
    </Box>
  );
};

export default About;
