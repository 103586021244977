import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  useMediaQuery,
  useTheme,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

import { useAddDoctorFormMutation  } from "../../state/api";
import Attachments from "../../components/Attachments ";

const DoctorsForm = () => {
  const theme = useTheme(); // Access Material-UI theme
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect mobile screens

  const [triggerSendDoctorForm, { isFetching }] = useAddDoctorFormMutation (); 




  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    gender:"",
    adresseProfessionnelle: "",
    situationProfessionnelle: "",
    telephone: "",
    email: "",
    declaration: false,
    files: [],
    idFile: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [validationError, setValidationError] = useState("");

  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB
  const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "application/pdf"];
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileUpload = (e, field) => {
    const files = Array.from(e.target.files);

    for (const file of files) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setUploadError(`Le fichier ${file.name} n'est pas autorisé.`);
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setUploadError(`Le fichier ${file.name} dépasse la taille maximale autorisée de 1 Mo.`);
        return;
      }
    }

    setUploadError("");
    setFormData((prevData) => ({
      ...prevData,
      [field]: field === "files" ? [...prevData.files, ...files] : files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check if all fields are filled
    if (
      !formData.nom ||
      !formData.prenom ||
      !formData.gender ||
      !formData.adresseProfessionnelle ||
      !formData.situationProfessionnelle ||
      !formData.telephone ||
      !formData.email ||
      !EMAIL_REGEX.test(formData.email) ||
      !formData.declaration ||
      !formData.files.length ||
      !formData.idFile
    ) {
      setValidationError("Veuillez renseigner toutes les informations correctement !");
      return;
    }
  
    setValidationError("");
    setIsLoading(true);
    setApiMessage("");
  
    try {
      // Create a FormData object
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("nom", formData.nom);
      formDataToSubmit.append("prenom", formData.prenom);
      formDataToSubmit.append("gender", formData.gender);
      formDataToSubmit.append("adresseProfessionnelle", formData.adresseProfessionnelle);
      formDataToSubmit.append("situationProfessionnelle", formData.situationProfessionnelle);
      formDataToSubmit.append("telephone", formData.telephone);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("declaration", formData.declaration);
  
      // Append files
      formData.files.forEach((file) => {
        formDataToSubmit.append("files", file);
      });
  
      formDataToSubmit.append("idFile", formData.idFile);

      console.log("FormData Contents:");
for (const pair of formDataToSubmit.entries()) {
  if (pair[1] instanceof File) {
    console.log(`${pair[0]}: ${pair[1].name}, size: ${pair[1].size}, type: ${pair[1].type}`);
  } else {
    console.log(`${pair[0]}: ${pair[1]}`);
  }
}




      
      
  
      // Trigger API call
      const response = await triggerSendDoctorForm(formDataToSubmit).unwrap(); // Trigger query and unwrap response
      setApiMessage("Nous avons bien reçu votre demande. Merci ! Notre équipe vous contactera prochainement.");
      console.log("API Response:", response); // Debug success response
    } catch (error) {
      setApiMessage(error.data.message || "Une erreur s'est produite.");
      console.error("API Error:", error); // Debug error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <section
        style={{
          backgroundColor: "#69A88D",
          height: "40vh",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color="white"
            sx={{
              marginBottom: "1em",
              fontSize: "2rem",
              width: "70%",
            }}
          >
            Veuillez remplir ce formulaire
          </Typography>
        </Box>
      </section>

      <Box
        sx={{
          padding: "2rem",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F8FAFC",
        }}
      >
        <Box
          sx={{
            maxWidth: "60rem",
            width: "100%",
            backgroundColor: "#FFFFFF",
            padding: "2rem",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, // Adjusts layout for mobile
                gap: "1.5rem",
              }}
            >
              {/* Nom */}
              <TextField
                fullWidth
                label="Nom"
                variant="outlined"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              />
              {/* Prénom */}
              <TextField
                fullWidth
                label="Prénom"
                variant="outlined"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              />
              {/* Sexe */}
              <TextField
                fullWidth
                select
                label="Sexe"
                variant="outlined"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Féminin">Féminin</MenuItem>
              </TextField>
              {/* Sexe */}
              { !isMobile && (
                <TextField
                fullWidth
                sx={{visibility :"hidden"}}
                >
                </TextField>
              )}
              {/* Adresse Professionnelle */}
              <TextField
                fullWidth
                label="Adresse Professionnelle"
                variant="outlined"
                name="adresseProfessionnelle"
                value={formData.adresseProfessionnelle}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              />
              {/* Situation Professionnelle */}
              <TextField
                fullWidth
                select
                label="Situation professionnelle actuelle"
                variant="outlined"
                name="situationProfessionnelle"
                value={formData.situationProfessionnelle}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              >
                <MenuItem value="Médecin généraliste">Médecin généraliste</MenuItem>
                <MenuItem value="Spécialiste">Spécialiste</MenuItem>
                <MenuItem value="Autre">Autre</MenuItem>
              </TextField>
              {/* Numéro de Téléphone */}
              <TextField
                fullWidth
                label="Numéro de téléphone"
                variant="outlined"
                name="telephone"
                value={formData.telephone}
                onChange={(e) => {
                  const onlyDigits = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                  handleChange({ target: { name: "telephone", value: onlyDigits } });
                }}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              />
              {/* Adresse Email */}
              <TextField
                fullWidth
                label="Adresse e-mail"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#F8FAFC",
                  "& .MuiInputLabel-root.Mui-focused": { color: "#69A88D" },
                }}
              />
            </Box>

            {/* Déclaration sur l'honneur */}
            <Box sx={{ marginTop: "2rem" }}>
              <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
                Déclaration sur l'honneur :
              </Typography>
              <FormControl>
                <RadioGroup
                  name="declaration"
                  value={formData.declaration}
                  onChange={(e) =>
                    setFormData({ ...formData, declaration: e.target.value === "true" })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          color: "#69A88D", // Default border color for unselected state
                          "&.Mui-checked": {
                            color: "#69A88D", // Green color when checked
                          },
                        }}
                      />
                    }
                    label={`Je soussigné(e) ${(formData.nom && formData.prenom) ? formData.nom + " " + formData.prenom : "" },  déclare que les informations fournies ci-dessus sont exactes et complètes. Je m'engage à informer FertiConnect de tout changement dans ma situation professionnelle ou personnelle.`}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            {/* Attachments */}
            <Attachments formData={formData} handleFileUpload={handleFileUpload}/>

            {/* Validation Error */}
            {validationError && (
              <Typography color="error" sx={{ marginTop: "1rem" }}>
                {validationError}
              </Typography>
            )}

            {/* Submit Button */}
            <Box sx={{ marginTop: "2rem", display: "flex", justifyContent: "flex-start" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{
                  backgroundColor: "#83C4A8",
                  "&:hover": { backgroundColor: "#57A07D" },
                }}
              >
                {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Envoyer"}
              </Button>
            </Box>

            {/* API Message */}
            <Typography
              sx={{
                marginTop: "1rem",
                visibility: apiMessage ? "visible" : "hidden",
                color: apiMessage.includes("succès") ? "green" : "red",
              }}
            >
              {apiMessage}
            </Typography>
          </form>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default DoctorsForm;
