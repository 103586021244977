import React , { useState } from 'react';
import { Box, Typography, Button, useMediaQuery, useTheme } from '@mui/material';
import { Link } from "react-router-dom";

import logoFooter from '../assets/logo_footer.png';

import facebookLogo from '../assets/Facebook.png';
import YoutubeLogo from '../assets/Youtube.png';
import TwitterLogo from '../assets/Twitter.png';
import linkedInLogo from '../assets/LinkedIn.png';
import { useAddToNewsletterMutation } from '../state/api';

const pages = [
  { name: "Accueil", path: "/" },
  { name: "A propos", path: "/about" },
  { name: "Contact", path: "/contact" },
  { name: "Accéder à l’application", path: "/" },
  { name: "Formulaire médecin", path: "/doctor-signup" },
  { name: "Termes et conditions d'utilisation", path: "/termes-conditions" },
  { name: "Confidentialité des données", path: "/confidentialite-donnees" },
];


const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [addToNewsletter] = useAddToNewsletterMutation();


  const handleEmailChange = (e) => {
    setNewsletterEmail(e.target.value); // Update the state with the input value
    setErrorMessage(''); // Clear the error message when typing
  };

  const handleSubscribeClick = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!newsletterEmail){
      return
    }

    if (!emailRegex.test(newsletterEmail)) {
      setErrorMessage("Format de l'email invalide.");
      return;
    }

    setIsSubmitting(true);
    try {
      // Call the mutation function
      
      const response = await addToNewsletter({ email: newsletterEmail }).unwrap();
      setErrorMessage(response.message);
    } catch (error) {
      setErrorMessage(error.data.message);
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <footer> 
      <Box
        sx={{
          backgroundColor: '#83C4A8', // Green background
          color: '#FFFFFF',
          padding: '3em 2em',
        }}
      >
        {/* Newsletter Section */}
        <Box
  sx={{
    backgroundColor: '#83C4A8',
    borderRadius: '10px',
    padding: '2em',
    marginBottom: '3em',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row', // Column layout for mobile, row for desktop
    justifyContent: isMobile ? 'center' : 'space-between', // Center align for mobile
    alignItems: 'center',
    textAlign: isMobile ? 'center' : 'left', // Center text for mobile
    gap: isMobile ? '1.5em' : '2em', // Adjust spacing for mobile
    boxShadow: '0px 4px 15px rgba(0, 0, 0, .4)',
  }}
>
  {/* Left Side Text */}
  <Typography
    variant="h4"
    fontWeight="bold"
    sx={{
      flex: '1',
      marginBottom: isMobile ? '1em' : '0',
      width: isMobile ? '100%' : '50%', // Full width for mobile
    }}
  >
    Vous Souhaitez être informé des dernières news ? <br />
    Inscrivez-vous à notre newsletter !
  </Typography>

  {/* Right Side Input and Button */}
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
      width: isMobile ? '100%' : '50%', // Full width for mobile
      maxWidth: isMobile ? 'none' : '40em', // Remove maxWidth restriction for mobile
    }}
  >
    <label
      htmlFor="newsletter-email"
      style={{
        textAlign: isMobile ? 'center' : 'left', // Center align for mobile
        width: '100%',
        color: '#ffffff',
      }}
    >
      Insérez votre adresse email
    </label>
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Stack input and button for mobile
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'space-between', // Center for mobile
        gap: isMobile ? '1em' : '1em',
        width: '100%',
      }}
    >
      <input
        id="newsletter-email"
        type="email"
        placeholder="Email"
        style={{
          padding: '1em 1em',
          borderRadius: '5px',
          border: '1px solid transparent',
          flex: isMobile ? 'none' : '0.8', // Remove flex for mobile to allow full width
          width: isMobile ? '100%' : 'auto', // Full width for mobile
          outline: 'none',
          transition: 'border 0.3s ease',
        }}
        onFocus={(e) => (e.target.style.border = 'none')}
        onBlur={(e) => (e.target.style.border = '1px solid transparent')}
        onChange={handleEmailChange}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#FF6F61',
          color: 'white',
          textTransform: 'none',
          padding: '0.5em 1em', 
          fontSize: '1rem',
          flex: isMobile ? 'none' : '1', // Remove flex for mobile
          width: isMobile ? '100%' : 'auto', // Full width for mobile
          fontWeight: 'bold',
          borderRadius: '5px',
          maxWidth: isMobile ? 'none' : '10em', // Remove max width for mobile
          flexShrink: '0', // Prevent shrinking 
          '&:hover': {
            backgroundColor: '#E65A50', // Slightly darker on hover
            },
        }}
        onClick={handleSubscribeClick}
        disabled={isSubmitting}
      >
            {isSubmitting ? (
        <Box
        component="span"
        sx={{
            border: '2px solid white',
            borderTop: '2px solid transparent',
            borderRadius: '50%',
            width: '1.8em',
            height: '1.8em',
            animation: 'spin 1s linear infinite',
        }}
        />
    ) : (
        'Envoyer'
    )}
      </Button>
    </Box>
    {/* Error or Success Message */}
    {errorMessage && (
              <span
                style={{
                  color: errorMessage.includes('enregistré') ? 'white' : 'red',
                  fontSize: '1em',
                  textAlign: 'center',
                }}
              >
                {errorMessage}
              </span>
            )}
  </Box>
</Box>


        {/* Footer Links and Info */}
        <Box
  sx={{
    display: 'flex',
    flexDirection: isMobile ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
    alignItems: isMobile ? 'center' : 'flex-start', // Center for mobile, left for desktop
    gap: '3em',
    textAlign: isMobile ? 'center' : 'left',
    padding: '0em 4em',
  }}
>
  {/* Logo and About */}
  <Box sx={{ flex: '1', order: 1 }}>
    <Box
      component="img"
      src={logoFooter} // Replace with actual logo path
      alt="Logo"
      sx={{ width: '100px', marginBottom: '1em' }}
    />
    <Typography variant="body2" sx={{ opacity: 0.8, maxWidth: '25em' }}>
      Lorem ipsum dolor sit amet consectetur adipiscing elit. Ut et massa mi.
      Aliquam in hendrerit urna. Pellentesque.
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'left',
        gap: '1em',
        marginTop: '4em',
      }}
    >
      <Box component="a" href="#">
        <Box
          component="img"
          src={facebookLogo} // Replace with actual paths
          alt="Facebook"
          sx={{ width: '24px', height: '24px' }}
        />
      </Box>
      <Box component="a" href="#">
        <Box
          component="img"
          src={TwitterLogo}
          alt="Twitter"
          sx={{ width: '24px', height: '24px' }}
        />
      </Box>
      <Box component="a" href="#">
        <Box
          component="img"
          src={linkedInLogo}
          alt="LinkedIn"
          sx={{ width: '24px', height: '24px' }}
        />
      </Box>
      <Box component="a" href="#">
        <Box
          component="img"
          src={YoutubeLogo}
          alt="YouTube"
          sx={{ width: '24px', height: '24px' }}
        />
      </Box>
    </Box>
  </Box>

  {/* Useful Links */}
  <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    justifyContent: isMobile ? "center" : "flex-start", // Align content properly
    alignItems: isMobile ? "flex-start" : "flex-end", // Adjust alignment for heading and links
    flex: 1,
    order: isMobile ? 3 : 2, // Place last for mobile, second for desktop
    textAlign: isMobile ? "left" : "right", // Ensure text alignment
    alignSelf: isMobile ? "flex-start" : "auto",
  }}
>
  <Typography
    variant="h6"
    fontWeight="bold"
    sx={{ marginBottom: "0.5em", alignSelf: isMobile ? "center" : "center" }} // Add spacing between heading and list
  >
    Autres Pages
  </Typography>

  <Box
    component="ul"
    sx={{
      listStyleType: "none", // Remove bullet points from outer container
      padding: 0,
      margin: 0, // Reset margin for proper alignment
      opacity: 0.8,
      textAlign: isMobile ? "center" : "left", // Match alignment with heading
    }}
  >
    {pages.map((page) => (
      <li
        key={page.name}
        style={{
          listStyleType: "disc",
          margin: "0 0 0 20px", // Proper indentation for list items
          textAlign: isMobile ? "left" : "left", // Align list items with heading
        }}
      >
        <Link
          to={page.path}
          style={{
            color: "#FFF",
            textDecoration: "none",
            fontSize: "1rem",
            textTransform: "none",
          }}
        >
          {page.name}
        </Link>
      </li>
    ))}
  </Box>
</Box>


  {/* Contact Info */}
  <Box
    sx={{
        display :"flex",
        flexDirection :"column",
        alignContent :"flex-end",
        justifyContent: isMobile ? 'center' : 'right',
        alignItems : 'flex-end',
        flex: '1',
        order: isMobile ? 2 : 3, // Place second for mobile, last for desktop
        textAlign: isMobile ? 'left' : 'left',
        alignSelf: isMobile ? 'flex-start' : 'auto',
    }}
  >
    <Typography sx={{margin: '0 3em 0 0'}} variant="h6" fontWeight="bold">
      Nous Contacter
    </Typography>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1em',
        fontSize : isMobile ? "1em" : "2em",
        gap: '1em',
      }}
    >
      <Box>
        <Typography variant="body2">Email</Typography>
        <Typography variant="body2">contact@ferticonnect.com</Typography>
      </Box>
      <Box>
        <Typography variant="body2">Téléphone</Typography>
        <Typography variant="body2">(00) 213 697 628 775</Typography>
      </Box>
      <Box>
        <Typography variant="body2">Adresse</Typography>
        <Typography variant="body2">Alger, Algérie</Typography>
      </Box>
    </Box>
  </Box>
</Box>

        {/* Footer Bottom */}
        <Box
          sx={{
            marginTop: '3em',
            textAlign: 'center',
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            paddingTop: '1em',
          }}
        >
          <Typography
            variant="body2"
            sx={{ opacity: 0.8, fontSize: '0.875rem' }}
          >
            © 2024 FertiConnect Tous les droits réservés
          </Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
