import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    mode: "light",
};



export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? "dark" : 'light';
        },
        signIn: (state,action) => {
            state.token = action.payload;
        },
        logOut: () => initialState,
    }
});

export const { setMode , signIn , logOut  } = globalSlice.actions;


export default globalSlice.reducer;
