import React from "react";
import { Box, Typography, Button } from "@mui/material";

const Attachments = ({ formData, handleFileUpload }) => {
  return (
    <Box sx={{ marginTop: "2rem" }}>
      {/* Diplomas and Certificates */}
      <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
        - Copie des diplômes et certificats.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          color: formData.files.length ? "inherit" : "#79787A",
          padding: "0.5rem 1rem",
          borderRadius: "0.5rem",
        }}
      >
        <Button
          variant="contained"
          component="label"
          sx={{
            maxWidth: "15rem",
            marginBottom: "1rem",
            backgroundColor: formData.files.length ? "#83C4A8" : "#F5F5F5",
            color: formData.files.length ? "white" : "#79787A",
            "&:hover": {
              backgroundColor: formData.files.length ? "#57A07D" : "#E0E0E0",
            },
          }}
        >
          {formData.files.length ? "Changer une pièce jointe" : "Rajouter une pièce jointe"}
          <input
            type="file"
            hidden
            multiple
            onChange={(e) => handleFileUpload(e, "files")}
          />
        </Button>
        {formData.files.length > 0 && (
          <Typography variant="body2">
            {formData.files.map((file) => file.name).join(", ")}
          </Typography>
        )}
      </Box>

      {/* ID Copy */}
      <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
        - Photocopie d'une pièce d'identité en cours de validité.
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          color: formData.idFile ? "inherit" : "#79787A",
          padding: "0.5rem 1rem",
          borderRadius: "0.5rem",
        }}
      >
        <Button
          variant="contained"
          component="label"
          sx={{
            maxWidth: "15rem",
            backgroundColor: formData.idFile ? "#83C4A8" : "#F5F5F5",
            color: formData.idFile ? "white" : "#79787A",
            "&:hover": {
              backgroundColor: formData.idFile ? "#57A07D" : "#E0E0E0",
            },
          }}
        >
          {formData.idFile ? "Changer une pièce jointe" : "Rajouter une pièce jointe"}
          <input
            type="file"
            hidden
            onChange={(e) => handleFileUpload(e, "idFile")}
          />
        </Button>
        {formData.idFile && (
          <Typography variant="body2">{formData.idFile.name}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Attachments;
