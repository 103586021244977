import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getXToken = () => {
    const xToken = ""; // Get x-Token from .env
    return xToken;
};

export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers, { endpoint }) => {
            if (endpoint === "addDoctorForm") {
                // Do not set Content-Type for multipart/form-data
            } else {
                headers.set("Content-Type", "application/json");
            }
            headers.set("x-token", getXToken());
            return headers;
        }
    }),
    reducerPath: "adminApi",
    tagTypes: ["User", "UserF", "Newsletter" , "doctorForm" , "contactForm"],
    endpoints: (build) => ({
        getUserF: build.query({
            query: (id) => `general/user/${id}`,
            providesTags: ["UserF"],
        }),
        addToNewsletter: build.mutation({
          query: ({ email }) => ({
              url: "user/newsletter",
              method: "POST",
              params: { email },
          }),
          providesTags: ["Newsletter"],
      }),
      sendMessage: build.query({
        query: (payload) => ({
            url: "forms/contact",
            method: "POST",
            body: payload, // Use body to send JSON payload
        }),
        transformResponse: (response) => response, // Optional: Customize response
        providesTags: ["contactForm"], // Provides tags for caching
        invalidatesTags: ["contactForm"], // Invalidates cache on success
    }),
    addDoctorForm: build.mutation({
        query: (formData) => ({
          url: "up/doctor",
          method: "POST",
          body: formData,
        }),
        transformResponse: (response) => response, // Optional: Customize response
        providesTags: ["doctorForm"], // Provides tags for caching
        invalidatesTags: ["doctorForm"], // Invalidates cache on success
    }),
    }),
});

export const {
    useAddToNewsletterMutation,
    useLazySendMessageQuery,
    useAddDoctorFormMutation

} = api;