import { useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useLazySendMessageQuery } from "../../state/api";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Contact = () => {
  const theme = useTheme(); // Access Material-UI theme
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detect mobile screens

  const [triggerSendMessage, { isFetching }] = useLazySendMessageQuery(); // Lazy query hook

  // States for form fields
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    sujet: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false); // State for spinner
  const [apiMessage, setApiMessage] = useState(""); // State for API feedback

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setApiMessage(""); // Reset the previous API message
    setIsLoading(true); // Show the spinner while sending the message
  
    try {
      console.log("Submitting form:", formData);
      const response = await triggerSendMessage(formData).unwrap(); // Call the API
      setApiMessage("Message envoyé avec succès !");
      console.log("API Response:", response); // Debug successful response
    } catch (error) {
      setApiMessage(error?.data?.message || "Une erreur est survenue.");
      console.error("API Error:", error); // Debug API error
    } finally {
      setIsLoading(false); // Hide the spinner after API call
    }
  };


  return (
    <Box>
      <section
        style={{
          backgroundColor: "#69A88D",
          height: "40vh",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            color="white"
            sx={{
              marginBottom: "1em",
              fontSize: isMobile ? "1.8rem" : "2.5rem",
              width: isMobile ? "80%" : "70%",
            }}
          >
            Nous sommes là pour vous. Besoin d'aide ou envie de discuter ?
            Faites-nous signe !
          </Typography>
        </Box>
      </section>

      <Box
        sx={{
          padding: isMobile ? "2rem 1rem" : "4rem 2rem",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#F8FAFC",
        }}
      >
        <Box
          sx={{
            maxWidth: "80rem",
            width: "100%",
            backgroundColor: "#FFFFFF",
            padding: "2rem",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              marginBottom: "1.5rem",
              textAlign: "left",
            }}
          >
            Restons connectés !
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr",
                gap: "1rem",
              }}
            >
              <TextField
                fullWidth
                label="Nom"
                variant="outlined"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                sx={{
                    backgroundColor: "#F8FAFC",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#69A88D", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#57A07D", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#69A88D", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "gray", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#69A88D", // Focused label color
                    },
                  }}
                />
              <TextField
                fullWidth
                label="Prénom"
                variant="outlined"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                sx={{
                    backgroundColor: "#F8FAFC",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#69A88D", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#57A07D", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#69A88D", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "gray", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#69A88D", // Focused label color
                    },
                  }}
                />
              <TextField
                fullWidth
                label="Adresse Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                sx={{
                    backgroundColor: "#F8FAFC",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#69A88D", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#57A07D", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#69A88D", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "gray", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#69A88D", // Focused label color
                    },
                  }}
                />
              <TextField
                fullWidth
                label="Sujet"
                variant="outlined"
                name="sujet"
                value={formData.sujet}
                onChange={handleChange}
                required
                sx={{
                    backgroundColor: "#F8FAFC",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#69A88D", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "#57A07D", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#69A88D", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "gray", // Default label color
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#69A88D", // Focused label color
                    },
                  }}
                />
            </Box>
            <TextField
              fullWidth
              label="Votre message"
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              required
              sx={{
                marginTop :"1rem",
                backgroundColor: "#F8FAFC",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#69A88D", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#57A07D", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#69A88D", // Focused border color
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "gray", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#69A88D", // Focused label color
                },
              }}
            />
            <Box
              sx={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}
                sx={{
                  backgroundColor: "#83C4A8",
                  "&:hover": {
                    backgroundColor: "#57A07D",
                  },
                }}
              >
                {isLoading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Envoyer"}
              </Button>
              <Typography
                sx={{
                  marginTop: "1rem",
                  visibility: apiMessage ? "visible" : "hidden",
                  color: apiMessage.includes("succès") ? "green" : "red",
                }}
              >
                {apiMessage}
              </Typography>
            </Box>
          </form>
        </Box>
      </Box>

      {/* New Section with Contact Cards */}
<Box
  sx={{
    backgroundColor: '#F8FAFC',
    padding: isMobile ? '2rem 1rem' : '4rem 2rem',
    display: 'flex',
    justifyContent: 'center',
  }}
>
  <Box
    sx={{
      maxWidth: '1200px',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
      gap: '2rem',
    }}
  >
    {/* Telephone Card */}
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '2rem',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: '#83C4A8',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto 1rem',
        }}
      >
        <PhoneIcon sx={{ color: '#FFFFFF', fontSize: '30px' }} />
      </Box>
      <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '0.5rem' }}>
        Telephone
      </Typography>
      <Typography variant="body2" color="text.secondary">
        (00) 213 697 628 775
      </Typography>
    </Box>

    {/* Email Card */}
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '2rem',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: '#83C4A8',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto 1rem',
        }}
      >
        <EmailIcon sx={{ color: '#FFFFFF', fontSize: '30px' }} />
      </Box>
      <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '0.5rem' }}>
        Email
      </Typography>
      <Typography variant="body2" color="text.secondary">
        contact@ferticonnect.com
      </Typography>
    </Box>

    {/* Address Card */}
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '2rem',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          backgroundColor: '#83C4A8',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto 1rem',
        }}
      >
        <LocationOnIcon sx={{ color: '#FFFFFF', fontSize: '30px' }} />
      </Box>
      <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: '0.5rem' }}>
        Adresse
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Alger, Algérie
      </Typography>
    </Box>
  </Box>
</Box>


      <Footer />
    </Box>
  );
};

export default Contact;