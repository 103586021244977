import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Termes = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Navbar color="black" />
      <Box
        sx={{
          padding: isMobile ? 2 : 4,
          textAlign: "center", // Center the text in the page
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Oswald",
            fontWeight: 600,
            letterSpacing: 0.5,
            marginBottom: 4,
          }}
        >
          TERMES ET CONDITIONS
        </Typography>
        <Box
          sx={{
            maxWidth: isMobile ? "100%" : "800px", // Constrain width for better readability
            margin: "0 auto", // Center the box horizontally
            textAlign: "left", // Ensure text remains left-aligned
          }}
        >
          {/* Section 1 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom>
              Ce site est exploité par ZEGHIMI Aya et DJEMA Israa. En utilisant ce site, vous acceptez les termes et conditions ainsi que la
              politique de confidentialité décrits ci-dessous. Si vous n'êtes pas d'accord avec ces politiques, veuillez cesser immédiatement
              d'utiliser ce site. Ces termes et conditions s'appliquent à l'utilisation de ce site Web.
            </Typography>
          </Box>
          {/* Section 2 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Acceptation des Conditions
            </Typography>
            <Typography variant="body1">
              Ce site Web est accessible dans le monde entier à toute personne ayant accès à Internet. L'accès et l'utilisation de ce site sont soumis à
              ces conditions générales. EN UTILISANT ET EN ACCÉDANT À CE SITE WEB, VOUS ACCEPTEZ, SANS LIMITATION, TOUS CES TERMES ET
              CONDITIONS. ZEGHIMI Aya et DJEMA Israa se réservent le droit de modifier ces conditions générales à tout moment en affichant une
              version mise à jour sur le site. En continuant à utiliser le site, vous acceptez que chaque utilisation soit soumise aux conditions en
              vigueur à ce moment-là.
            </Typography>
          </Box>
          {/* Section 3 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Objet du Site
            </Typography>
            <Typography variant="body1">
              Ce site est exploité dans le but de fournir des informations générales sur l'application FertiConnect et ses services.
            </Typography>
          </Box>
          {/* Section 4 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Propriété des Contenus, Droits d'Auteur et Marques Déposées
            </Typography>
            <Typography variant="body1">
              Tous les contenus utilisés et affichés sur ce site Web, y compris le texte, les logiciels, les images, les graphiques, les illustrations, les
              vidéos, les audios, les logos et les marques, sont la propriété de FertiConnect ou sont sous licence, et sont protégés par
              les lois sur la propriété intellectuelle, les marques déposées et d’autres droits légaux. Le site peut également contenir des marques de
              tiers. Ces marques déposées sont la propriété de leurs propriétaires respectifs, et vous vous engagez à ne pas les utiliser ou les afficher
              sans autorisation écrite préalable.
            </Typography>
          </Box>
          {/* Section 5 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Liens vers d'Autres Sites
            </Typography>
            <Typography variant="body1">
            FertiConnect n'examine pas et ne surveille pas le contenu des sites externes pouvant être liés à ce site et n'est pas
              responsable de leur contenu. L'utilisation de liens vers de tels sites se fait à vos propres risques.
            </Typography>
          </Box>
          {/* Section 6 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Déclaration de Confidentialité
            </Typography>
            <Typography variant="body1">
              Veuillez <a target="_blank" style={{ color :'#69A88D', fontWeight :"700"   }} href="https://ferticonnect.com/confidentialite-donnees">cliquer ici</a> pour consulter la politique de confidentialité du site Web, qui est incorporée par référence dans ces termes et
              conditions. En acceptant ces termes, vous consentez à être lié(e) par la politique de confidentialité et reconnaissez avoir reçu des
              informations détaillées sur le traitement de vos données personnelles.
            </Typography>
          </Box>
          {/* Section 7 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              EXCLUSION DE GARANTIES
            </Typography>
            <Typography variant="body1">
              CE SITE WEB EST FOURNI "EN L'ÉTAT" SANS GARANTIE D'AUCUNE SORTE, EXPRESSE OU IMPLICITE, Y COMPRIS LES GARANTIES DE
              PROPRIÉTÉ, DE QUALITÉ MARCHANDE OU D'ADÉQUATION À UN USAGE PARTICULIER.
            </Typography>
          </Box>
          {/* Section 8 */}
          <Box  sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              LIMITATION DE RESPONSABILITÉ
            </Typography>
            <Typography variant="body1">
              VOTRE UTILISATION DE CE SITE WEB EST À VOS PROPRES RISQUES. FERTICONNECT ET SES AFFILIÉS DÉCLINENT TOUTE
              RESPONSABILITÉ POUR TOUTE PERTE, DOMMAGE DIRECT, INDIRECT OU SPÉCIAL LIÉ À VOTRE UTILISATION OU INCAPACITÉ À UTILISER CE
              SITE WEB OU LES INFORMATIONS DISPONIBLES SUR CELUI-CI.
            </Typography>
          </Box>
          {/* Section 9 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Divisibilité
            </Typography>
            <Typography variant="body1">
              Si une partie de ces termes et conditions est jugée invalide ou inapplicable par un tribunal compétent, cela n'affectera pas la validité
              des autres dispositions, qui resteront en vigueur.
            </Typography>
          </Box>
          {/* Section 10 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Résolution des Litiges
            </Typography>
            <Typography variant="body1">
              Ces termes et conditions ainsi que tout litige découlant de ce site Web ou s'y rapportant sont régis par les lois de l'Algérie. Les tribunaux de Alger auront compétence exclusive pour résoudre tout litige relatif à
              ces termes et conditions.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Termes;
