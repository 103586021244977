import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Confidentialite = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Navbar color="black" />
      <Box
        sx={{
          padding: isMobile ? 2 : 4,
          textAlign: "center", // Center the content
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Oswald",
            fontWeight: 600,
            letterSpacing: 0.5,
            marginBottom: 4,
          }}
        >
          CONFIDENTIALITÉ DES DONNÉES
        </Typography>
        <Box
          sx={{
            maxWidth: isMobile ? "100%" : "800px", // Constrain width for better readability
            margin: "0 auto", // Center horizontally
            textAlign: "left", // Ensure text remains left-aligned
          }}
        >
          {/* Section 1 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom>
              Ce site est exploité par ZEGHIMI Aya et DJEMA Israa. En utilisant ce site, vous acceptez la déclaration de confidentialité et les
              conditions générales énoncées ci-dessous. Si vous n'êtes pas d'accord avec ces politiques, cessez immédiatement d'utiliser ce site.
            </Typography>
          </Box>
          {/* Section 2 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Notre déclaration de confidentialité
            </Typography>
            <Typography variant="body1">
            La présente déclaration de confidentialité (la « Déclaration N°1  ») est applicable aux informations fournies par l'Utilisateur de Ferticonnect. En raison de notre engagement à respecter la confidentialité de nos utilisateurs, nous avons décidé de communiquer nos règles et pratiques en matière d'information. Toutes les informations que vous nous fournissez seront soumises aux règles et principes détaillés dans la Déclaration. Toutes les questions et commentaires relatifs à notre politique de confidentialité doivent être adressés à Ferticonnect via la page Contact. Nous collectons des informations de différentes manières sur notre site :
            <ul>
              <li>Fichiers journaux</li>
              <li>Cookies</li>
              <li>Contact (email)</li>
            </ul>
            </Typography>
          </Box>
          {/* Section 3 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Fichiers journaux
            </Typography>
            <Typography variant="body1">
              Chaque fois que vous vous connectez à notre site, nous collectons certaines informations concernant votre connexion et votre
              ordinateur. Cela inclut votre adresse IP, le type et les paramètres de votre navigateur, votre type d'ordinateur et les systèmes
              d'exploitation que vous utilisez.  Nous utilisons les adresses IP pour analyser les tendances, administrer le site, suivre les déplacements des utilisateurs et recueillir des informations démographiques pour une utilisation globale. Les informations collectées dans les fichiers journaux ne sont pas liées à des informations personnellement identifiables.
            </Typography>
          </Box>
          {/* Section 4 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Cookies
            </Typography>
            <Typography variant="body1">
              Un cookie est une donnée stockée sur le disque dur de votre ordinateur, qui contient des informations permettant d’améliorer et de
              faciliter votre expérience de navigation sur notre site Web.
            </Typography>
          </Box>
          {/* Section 5 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Contact (email)
            </Typography>
            <Typography variant="body1">
              La page Contact de ce site vous permet de nous envoyer des e-mails. Nous conserverons votre adresse e-mail et toute autre
              information que vous nous communiquerez si nécessaire. Ces informations sont conservées uniquement pour faciliter la communication avec vous. Si vous souhaitez que nous supprimions votre adresse e-mail ou toute autre information que vous nous avez envoyée, veuillez nous envoyer un e-mail pour en demander la suppression, et nous respecterons vos souhaits.
            </Typography>
          </Box>
          {/* Section 6 */}
          
          {/* Section 7 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Propriété des informations
            </Typography>
            <Typography  variant="body1">
              ZEGHIMI Aya et DJEMA Israa recueillent toutes les informations que vous fournissez sur ce site Web et sont les seules propriétaires des
              informations recueillies sur ce dernier. <br/>EN NOUS FOURNISSANT VOS RENSEIGNEMENTS PERSONNELLEMENT IDENTIFIABLES, VOUS NOUS AUTORISEZ EXPRESSÉMENT À LES UTILISER CONFORMÉMENT AUX RÈGLES ET PRINCIPES DE LA DÉCLARATION. Si vous nous fournissez d'autres renseignements, comme des suggestions sur le site ou nos produits, ou toute autre suggestion, idée ou information, ces dernières nous appartiendront et pourront être utilisées et divulguées par nous à notre discrétion, sans compensation.
            </Typography>
          </Box>
          {/* Section 8 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Liens
            </Typography>
            <Typography variant="body1">
              Ce site Web peut contenir des liens vers d'autres sites. Veuillez noter que nous ne sommes pas responsables des pratiques de
              confidentialité de ces autres sites. Nous vous encourageons à en tenir compte lorsque vous quittez notre site et à lire les déclarations de confidentialité de chaque site Web qui collecte des renseignements personnellement identifiables. Cette déclaration s'applique uniquement aux renseignements que nous collectons via ce site.
            </Typography>
          </Box>
          {/* Section 9 */}
          <Box sx={{ marginBottom: 4 }}>
            <Typography sx={{
              fontWeight : 700
            }} variant="h5" gutterBottom>
              Modifications de notre déclaration de confidentialité
            </Typography>
            <Typography variant="body1">
              Nous nous réservons le droit de modifier notre politique de confidentialité à tout moment en publiant la politique révisée sur le site. Il
              est de votre responsabilité de vérifier périodiquement les modifications que nous pourrions apporter à cette politique. Si vous n'êtes pas d'accord avec les modifications apportées à notre politique, envoyez-nous un e-mail demandant la suppression de toute information que vous avez déjà soumise, et nous respecterons vos souhaits. Votre utilisation continue de ce site après la publication de modifications signifie que vous acceptez les modifications.
              Vous avez le droit d'accéder à vos données personnelles à tout moment et de demander des modifications ou la suppression de vos données personnelles à tout moment en nous envoyant un e-mail.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Confidentialite;
